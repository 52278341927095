<template>
  <div>
    <div
      v-if="premium"
      title="Premium Content"
      @click="checkContent(card, 'video')">
      <div class="card-wrapper">
        <b-overlay
          class="premium-overlay"
          :show="premium"
          :opacity="0.5"
          variant="dark"
          no-center
          rounded="lg"
          style="border-radius: 10px !important;">
          <template #overlay>
            <b-badge
              class="position-absolute premium-badge"
              variant="primary"
              v-if="premium"
              style="top: 7%; left: 5%;">
              Premium
            </b-badge>
            <i class="fas fa-lock" style="color: transparent;"></i>
          </template>
          <img
            class="card-carousel-card-thumbnail"
            :src="card.thumbnail"
            :alt="card.media_title"
            :title="card.media_title"
            :style="{
              width  : `${cardWidthStyle}px`,
              height : `${cardHeightStyle}px`}"
          />
        </b-overlay>
      </div>
    </div>

    <a
      :href="`#wistia_${card.wistia_id}`"
      @click="$emit('selectedVid')"
      v-else>
      <div class="card-wrapper">
        <img
          class="card-carousel-card-thumbnail"
          :src="card.thumbnail"
          :alt="card.media_title"
          :title="card.media_title"
          :style="{
            width  : `${cardWidthStyle}px`,
            height : `${cardHeightStyle}px`}"
        />
      </div>
    </a>
  </div>
</template>

<script>
  import PremiumMixin from '@/mixins/PremiumMixin';

  export default {
    mixins : [
      PremiumMixin,
    ],
    props : {
      card : {
        type     : Object,
        required : true,
      },
      columnItems : {
        type     : Number,
        required : true,
      },
      cardCarouselWidth : {
        type     : Number,
        required : true,
      },
    },
    computed : {
      cardWidthStyle() {
        const gap = 18; // left and right margin 9px ea
        return (this.cardCarouselWidth / this.columnItems) - gap;
      },
      cardHeightStyle() {
        //16:10 ratio
        return this.cardWidthStyle ? this.cardWidthStyle * 0.625 : 0;
      },
      allowedUser() {
        // const user = this.$store.getters['user/user'];
        // return (user.subscription.class_name === 'free' ||
        //   user.user_type === 1) ? false : true;
        return true;
      },
      premium() {
        return (this.card.is_free === 0 && !this.allowedUser) ? true : false;
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/settings";

  .premium-badge {
    position: absolute;
    background-color: $primary-color !important;
  }

  .card-wrapper {
    height: auto;
    border-radius: 10px;
    background-color: rgba(255,255,255,0.5);
    &:hover {
      background-color: #000;
    }
  }
  .card-carousel-card-thumbnail {
    height: auto;
    border-radius: 10px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
    transition: opacity 150ms linear;
    user-select: none;
    vertical-align: bottom;

    &:hover {
      opacity: 0.7;
    }
  }

  .premium-overlay {
    border-radius: 10px;
  }
</style>